import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import filterLocale from '../utils/filter-locale'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Check, X } from 'react-feather'
import {
	DifferenceSection,
	DifferenceContainer,
	DiffTitle,
	DiffSubtext,
	DiffParagraph,
	ListContainer,
	List,
	ListItem,
	ItemName,
	ItemList,
	DiffLastText,
} from './styles'
const Difference = ({ locale }) => {
	const { difference, differencebanner } = useStaticQuery(graphql`
		{
			difference: allContentfulFeaturedItems(
				filter: { contentful_id: { eq: "6ATSr6ssLtlXBi3JnetuD0" } }
			) {
				edges {
					node {
						node_locale
						items {
							name
							list {
								icon
								item
							}
						}
						subtitle
					}
				}
			}
			differencebanner: allContentfulBanner(
				filter: { contentful_id: { eq: "1joetoXkHUkHL1HFN94FwB" } }
			) {
				edges {
					node {
						node_locale
						hook
						subtext
						description {
							json
						}
					}
				}
			}
		}
	`)

	const [{ node }] = filterLocale(difference.edges, locale)
	const [{ node: diffbanner }] = filterLocale(differencebanner.edges, locale)

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<DiffParagraph>{children}</DiffParagraph>
			),
		},
	}
	const colors = ['#58b8ce', '#2f3648', '#262c3b']
	const iconColors = ['#fff', '#58b8ce', '#58b8ce']
	const textColor = ['#353e47', '#fff', '#fff']
	return (
		<DifferenceSection>
			<DifferenceContainer>
				<DiffTitle>{diffbanner.hook}</DiffTitle>
				<DiffSubtext>{diffbanner.subtext}</DiffSubtext>
				{documentToReactComponents(diffbanner.description.json, options)}
			</DifferenceContainer>

			<ListContainer>
				<List>
					{node.items.map((item, index) => {
						return (
							<ListItem key={`difference-list-item-${index}`} bgColor={colors[index]}>
								<ItemName>{item.name}</ItemName>

								<ItemList>
									{item.list.map((x, i) => {
										return (
											<li key={i}>
												{x.icon ? (
													<Check color={iconColors[index]} strokeWidth="3" />
												) : (
													<X strokeWidth="3" color="#e02424" />
												)}
												<p css={{ color: `${textColor[index]}` }}>{x.item}</p>
											</li>
										)
									})}
								</ItemList>
							</ListItem>
						)
					})}
				</List>
			</ListContainer>
			<DiffLastText>
				Powerfully manufactured for the toughest of mess, your choice from our
				huge range of Maytronics automatic pool cleaners offers you advanced
				technology and performance vs. common pool suction cleaners or pool
				vacuum cleaners that can’t clean your pool to the standards you need.
			</DiffLastText>
		</DifferenceSection>
	)
}

export default Difference
