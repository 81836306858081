import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../utils/filter-locale'

import {
	WhichModelSection,
	WhichModelContainer,
	WhichModelP,
	WhichModelTextContainer,
	WhichModelHook,
	WhichModelSubtext,
	WhichModelDescription,
	WhichModelButton,
	Italic,
} from './styles'

const WhichModel = ({ locale }) => {
	const {
		whichmodel,
		whichmodelXl,
		whichmodelLg,
		whichmodelBase,
	} = useStaticQuery(graphql`
		{
			whichmodel: allContentfulBanner(
				filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }
			) {
				edges {
					node {
						hook
						subtext
						node_locale
						mainActionText
						mainActionUrl
						description {
							json
						}
						backgroundImage {
							fluid(
								maxWidth: 1600
								maxHeight: 767
								quality: 80
								cropFocus: BOTTOM
								resizingBehavior: FILL
							) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelXl: allContentfulBanner(
				filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 1400
								maxHeight: 678
								quality: 80
								cropFocus: BOTTOM
								resizingBehavior: FILL
							) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelLg: allContentfulBanner(
				filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 1280
								maxHeight: 678
								quality: 80
								cropFocus: BOTTOM
								resizingBehavior: FILL
							) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
			whichmodelBase: allContentfulBanner(
				filter: { contentful_id: { eq: "5YIiVAvRGqzyN2zXXlmWGv" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 1024
								maxHeight: 540
								quality: 80
								cropFocus: BOTTOM
								resizingBehavior: FILL
							) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
		}
	`)

	const [
		{
			node: {
				hook,
				subtext,
				backgroundImage,
				description,
				mainActionText,
				mainActionUrl,
			},
		},
	] = filterLocale(whichmodel.edges, locale)
	const [{ node: whichmodelxl }] = filterLocale(whichmodelXl.edges, locale)
	const [{ node: whichmodellg }] = filterLocale(whichmodelLg.edges, locale)
	const [{ node: whichmodelbase }] = filterLocale(whichmodelBase.edges, locale)
	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<WhichModelP>{children}</WhichModelP>
			),
		},
	}

	const sources = [
		{
			...backgroundImage.fluid,
			srcSet: `${backgroundImage.fluid.src}`,
			srcWebp: `${backgroundImage.fluid.srcWebp}`,
			media: `(min-width: 1601px)`,
		},
		{
			...whichmodelxl.backgroundImage.fluid,
			srcSet: `${whichmodelxl.backgroundImage.fluid.src}`,
			srcWebp: `${whichmodelxl.backgroundImage.fluid.srcWebp}`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...whichmodellg.backgroundImage.fluid,
			srcSet: `${whichmodellg.backgroundImage.fluid.src}`,
			srcWebp: `${whichmodellg.backgroundImage.fluid.srcWebp}`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...whichmodelbase.backgroundImage.fluid,
			srcSet: `${whichmodelbase.backgroundImage.fluid.src}`,
			srcWebp: `${whichmodelbase.backgroundImage.fluid.srcWebp}`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
	]

	const newhook = hook.split(' ')
	const findindex = newhook.indexOf('which')
	const x = <Italic>{newhook[findindex]}</Italic>
	newhook.splice(findindex, 1, x)
	const xx = newhook.map((el, i) => {
		if (typeof el === 'string') {
			newhook.splice(i, 1, `${el} `)
		} else {
			newhook.splice(i + 1, 0, ' ')
		}
	})
	return (
		<WhichModelSection sources={sources}>
			<WhichModelContainer sources={sources}>
				<WhichModelTextContainer>
					<WhichModelHook>{newhook}</WhichModelHook>
					<WhichModelSubtext>{subtext}</WhichModelSubtext>
					<WhichModelDescription>
						{documentToReactComponents(description.json, option)}
						<WhichModelButton to={`${mainActionUrl}/ `}>
							{mainActionText}
						</WhichModelButton>
					</WhichModelDescription>
				</WhichModelTextContainer>
			</WhichModelContainer>
		</WhichModelSection>
	)
}

export default WhichModel
