import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import filterLocale from '../utils/filter-locale'

import {
	CcSection,
	CcContainer,
	CcTextContainer,
	CcHook,
	CcSubtext,
	FeatureContainer,
	FeatureItem,
	FeatureText,
} from './styles'
const ClimateCare = ({ locale }) => {
	const {
		climatecare,
		features,
		climatecareLg,
		climatecareSm,
	} = useStaticQuery(graphql`
		{
			climatecare: allContentfulBanner(
				filter: { contentful_id: { eq: "3BE6Edj4LpmpxPBvVEw3yn" } }
			) {
				edges {
					node {
						hook
						node_locale
						subtext
						image {
							description
							fluid(maxWidth: 300, maxHeight: 300, quality: 80) {
								src
								srcWebp
							}
						}
						backgroundImage {
							description
							fluid(
								maxWidth: 1600
								maxHeight: 900
								quality: 70
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						description {
							json
						}
					}
				}
			}
			features: allContentfulFeaturedItems(
				filter: { contentful_id: { eq: "5r7mlLmBGrxsBkPYKxBcLy" } }
			) {
				edges {
					node {
						node_locale
						items {
							name
							image {
								description
								file {
									url
								}
							}
							body {
								json
							}
						}
					}
				}
			}
			climatecareLg: allContentfulBanner(
				filter: { contentful_id: { eq: "3BE6Edj4LpmpxPBvVEw3yn" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							description
							fluid(
								maxWidth: 1000
								maxHeight: 700
								quality: 70
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			climatecareSm: allContentfulBanner(
				filter: { contentful_id: { eq: "3BE6Edj4LpmpxPBvVEw3yn" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							description
							fluid(
								maxWidth: 500
								maxHeight: 700
								quality: 70
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
	`)

	const [
		{
			node: { hook, subtext, image, backgroundImage },
		},
	] = filterLocale(climatecare.edges, locale)
	const [{ node: feature }] = filterLocale(features.edges, locale)
	const [{ node: climatecarelg }] = filterLocale(climatecareLg.edges, locale)
	const [{ node: climatecaresm }] = filterLocale(climatecareSm.edges, locale)

	const sources = [
		{
			...backgroundImage.fluid,
			srcSet: `${backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1601px)`,
		},
		{
			...climatecarelg.backgroundImage.fluid,
			srcSet: `${climatecarelg.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${climatecarelg.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1279px)`,
		},
		{
			...climatecaresm.backgroundImage.fluid,
			srcSet: `${climatecaresm.backgroundImage.fluid.src} 320w`,
			srcSetWebp: `${climatecaresm.backgroundImage.fluid.srcWebp} 320w`,
			media: `(min-width: 320px) and (max-width: 767px)`,
		},
	]

	const option = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<FeatureText>{children}</FeatureText>
			),
		},
	}

	return (
		<CcSection sources={sources}>
			<CcContainer sources={sources}>
				<CcTextContainer>
					<CcHook>{hook}</CcHook>
					<CcSubtext>{subtext}</CcSubtext>
					<picture>
						<source srcSet={image.fluid.srcWebp} type="image/webp"></source>
						<img src={image.fluid.src} alt={image.description} loading="lazy" />
					</picture>
				</CcTextContainer>
			</CcContainer>

			<FeatureContainer>
				{feature.items.map((item, i) => {
					return (
						<FeatureItem key={`feature-item-${i}`}>
							<img
								src={item.image.file.url + '?fm=png&fl=png8&w=150&h=150'}
								alt={item.image.description}
								loading="lazy"
							/>
							<h4>{item.name}</h4>
							{documentToReactComponents(item.body.json, option)}
						</FeatureItem>
					)
				})}
			</FeatureContainer>
		</CcSection>
	)
}

export default ClimateCare
