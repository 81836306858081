import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import filterLocale from '../utils/filter-locale'
import {
	RelaxSection,
	RelaxContainer,
	SectionSubtext,
	TextsContainer,
	Heading4,
	RightTexts,
	RelaxImageContainer,
	RelaxImage,
} from './styles'

const relax = ({ locale }) => {
	const { relaxbanner } = useStaticQuery(graphql`
		{
			relaxbanner: allContentfulBanner(filter: { hook: { eq: "Relax" } }) {
				edges {
					node {
						node_locale
						contentful_id
						hook
						subtext
						description {
							json
						}
						backgroundImage {
							fluid(
								maxWidth: 1600
								quality: 80
								cropFocus: BOTTOM
								resizingBehavior: FILL
							) {
								src
								srcWebp
								base64
							}
						}
					}
				}
			}
		}
	`)

	const [{ node }] = filterLocale(relaxbanner.edges, locale)

	// const options = {
	// 	renderNode: {
	// 		[BLOCKS.HEADING_4]: (node, children) => <Heading4>{children}</Heading4>,
	// 	},
	// }

	const sources = [
		{
			...node.backgroundImage.fluid,
			srcSet: `${node.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${node.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
	]

	return (
		<RelaxSection>
			<RelaxContainer>
				{/* <Relax color="#FDB515" width="340" height="180"/> */}
				{/* <h1 tw="text-4xl font-semibold font-display text-gray-700 text-center w-full mb-4 uppercase">
					The Smartest Maytronics Robotic Pool Cleaner Products Yet
				</h1> */}
				{/* <SectionSubtext>AUTOMATIC ROBOTIC POOL CLEANERS</SectionSubtext> */}
				<SectionSubtext>
					The Smartest Maytronics Robotic Pool Cleaner Products Yet
				</SectionSubtext>
				<Heading4>Why a Maytronics Robotic Pool Cleaner is Better</Heading4>
				<TextsContainer>
					<p>
						For too long, pool owners have been burdened with the maintenance of
						their pools. Are you trying to fit in essential swimming pool
						cleaning around a busy life? Still using a substandard robot pool
						vacuum cleaner that doesn’t do the job properly? Now you can
						maintain a crystal clear pool without the hours of frustration,
						using the smartest technology controlled by you, straight from your
						phone app - the Dolphin difference! Maytronics has pioneered
						innovative pool cleaning technology for over 35 years and is proud
						to be the market leader for robotic pool cleaners in Australia. When
						compared to traditional automatic swimming pool vacuum cleaners, a
						Maytronics product continues to present technology far ahead of its
						time.
					</p>

					<RightTexts>
						<ul>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#fff"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<polyline points="20 6 9 17 4 12"></polyline>
								</svg>
								<p>
									The Maytronics smart swimming pool robot cleaner essentially
									sucks and scrubs your pool, leaving you with crystal clear
									water.
								</p>
							</li>
							<li>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#fff"
									stroke-width="3"
									stroke-linecap="round"
									stroke-linejoin="round"
								>
									<polyline points="20 6 9 17 4 12"></polyline>
								</svg>
								<p>
									Your Dolphin robotic pool cleaner allows you to spend more
									time enjoying your pool with the kids and friends than time
									cleaning it.
								</p>
							</li>
						</ul>

						<p>
							Relax and enjoy your pool, knowing that you’re using one of the
							smartest and bestselling automatic pool cleaners available.
						</p>
					</RightTexts>
				</TextsContainer>
			</RelaxContainer>

			<RelaxImageContainer sources={sources}>
				<RelaxImage sources={sources} />
			</RelaxImageContainer>
		</RelaxSection>
	)
}

export default relax
